var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _vm._v(" " + _vm._s(_vm.postObj.customName) + " "),
            _c(
              "el-tag",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "mini" },
              },
              [_vm._v(_vm._s(_vm.postObj.createDate))]
            ),
            _c("el-tag", { attrs: { size: "mini" } }, [
              _vm._v(_vm._s(_vm.postObj.activityName)),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-collapse",
            {
              staticStyle: { padding: "0 15px", background: "#fff" },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _vm.postObj.frmType == 1
                    ? _c(
                        "el-card",
                        { staticClass: "box-card" },
                        [
                          _c(
                            _vm.postObj.dbName != "" &&
                              _vm.postObj.dbName + "Detail",
                            {
                              tag: "component",
                              attrs: { frmData: _vm.postObj.frmData },
                            }
                          ),
                        ],
                        1
                      )
                    : _vm.postObj.frmType === 2
                    ? [
                        _c(
                          "div",
                          { staticStyle: { "padding-right": "25px" } },
                          [
                            _vm.frmContentData.list &&
                            _vm.frmContentData.list.length > 0
                              ? _c("CreatedForm", {
                                  ref: "createdForm",
                                  attrs: {
                                    insite: true,
                                    data: _vm.frmContentData,
                                    value: _vm.defaultValue,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _c("el-card", { staticClass: "box-card" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.postObj.frmPreviewHtml),
                          },
                        }),
                      ]),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "流转记录", name: "2" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "600",
                      },
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v("流转记录")]
                  ),
                  _c(
                    "el-timeline",
                    _vm._l(_vm.histories, function (history, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: { timestamp: history.createDate },
                        },
                        [_vm._v(" " + _vm._s(history.content) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "流程图", name: "3" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "600",
                      },
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v("流程图")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { height: "500px" } },
                    [
                      _c("CreatedFlow", {
                        ref: "createdFlow",
                        attrs: {
                          "form-template": _vm.currentForm,
                          isEdit: true,
                          isShowContent: _vm.isShowContent,
                          "scheme-content": _vm.postObj.schemeContent,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }